import React from 'react';
import { useState } from 'react';
import chair from "../Asset/images/LeatherChair01.png";
import chair2 from "../Asset/images/LeatherChair02.png";
import chair3 from "../Asset/images/LeatherChair03.png";
import chair4 from "../Asset/images/LeatherChair04.png";
import chaier_cont1 from '../Asset/images/Leather01.png'
import chaier_cont2 from '../Asset/images/Leather02.png'
import chaier_cont3 from '../Asset/images/Leather03.png'
import chaier_cont4 from '../Asset/images/Leather04.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

const Interier = () => {

    const data = [
        {
            id: "1",
            key: "1",
            img: chair,
            control_img: chaier_cont1,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        },
        {
            id: "2",
            key: "2",
            img: chair2,
            control_img: chaier_cont2,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        },
        {
            id: "3",
            key: "3",
            img: chair3,
            control_img: chaier_cont3,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        },
        {
            id: "4",
            key: "4",
            img: chair4,
            control_img: chaier_cont4,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        },
    ]

    const [toggle, setToggle] = useState("1");

    return (
        <div className='interier pt-8 md:pt-20'>
            <div className='container p-0'>
                <div className='row interier_main'>
                    <div className='col-md-6 flex  items-end'>
                        {
                            data.map(({ key, img, }) => {
                                return (
                                    <div className='chair'>
                                        {toggle === key ? (
                                            <>
                                                <img src={img} key={key} alt="chair" />
                                            </>
                                        ) : null}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='col-md-6 md:p-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className=' text-7xl pb-3 md:text-8xl md:pb-9 font-thin banner_title text-left md:text-right '>Interier</h1>
                                    <p className='text-white opacity-50 text-2xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                </div>
                                <div className='col-md-12 right_inter_img mt-4'>
                                    <div className='row'>
                                            {
                                                data.map(({ key, control_img, content }) => {
                                                    return (
                                                        <div className='col-md-3 col-lg-5 mb-2 mt-2 desktop_device'>
                                                            <img onClick={() => setToggle(key)} src={control_img} alt="chair_control"></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                        <div className='mobile_device interiers_main'>
                                            {
                                                data.map(({ key, control_img, }) => {
                                                    return (
                                                        <div className='col-md-3 col-lg-5 mb-2 mt-2 control_img '>
                                                            <img onClick={() => setToggle(key)} src={control_img} alt="chair_control"></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Interier