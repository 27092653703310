import React from 'react';
import founder from "../Asset/images/Founder.png"

const Founder = () => {
    return (
        <div className='founder bg-[#272727] pt-8 md:pt-20'>
            <div className='container'>
                <div className='row'>
                    <div className='offset-md-2 col-md-8'>
                        <div className='row flex justify-evenly items-center gx-0'>
                            <div className='col-md-5'>
                                <img src={founder} alt="founder"></img>
                            </div>
                            <div className='col-md-7'>
                                <h1 className='text-5xl font-thin text-white mb-2  md:text-8xl md:mb-0'>Founder <br></br>
                                    <span className='banner_title'>Mussaed</span>
                                </h1>
                                <p className='text-white text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Founder