import React from 'react';
import Banner from './Components/Banner';
import Contacts from './Components/Contacts';
import Exterier from './Components/Exterier';
import Founder from './Components/Founder';
import Future from './Components/Future';
import Interier from './Components/Interier';
import Navbar from "./Components/Navbar";
import Tech from './Components/Tech';


function App() {
	return (
		<nav>
			<Navbar/>
			<Banner />
			<Future />
			<Exterier />
			<Tech />
			<Interier />
			<Founder />
			<Contacts/>
		</nav>
	);
}

export default App;
