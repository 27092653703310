import React from 'react';
import { Link } from 'react-router-dom'
import banner from "../Asset/images/Car-Banner.png";
import facebook from "../Asset/images/facebook.png";
import insta from "../Asset/images/instagram.png";
import twiter from "../Asset/images/twitter.png";
import behance from "../Asset/images/behance.png";

const Banner = () => {
    return (
        <div className='banner'>
            <div className='row gx-0 banner_main'>
                <div className='col-md-1 bg-[#000] social '>
                    {/* <nav>
                        <ul className='design'>
                            <li>
                                <Link to="#">
                                    <img src={insta} alt="insta" className='w-4'></img>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={twiter} alt="twiter" className='w-5'></img>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={behance} alt="behance" className='w-5'></img>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={facebook} alt="facebook" className='w-5'></img>
                                </Link>
                            </li>
                        </ul>
                    </nav> */}
                </div>
                <div className='col-md-11  main_banners'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <div className='banner_content'>
                                    <h1 className='banner_title'>Introducing <br></br>
                                        Aseel
                                    </h1>
                                    {/* <Link to="#future" className='banner_read'>
                                       
                                    </Link> */}
                                    <a href='#future' className='banner_read'>
                                        Learn More
                                    </a>
                                </div>
                            </div>
                            <div className='col-md-7'>
                                <div className='banner_cars'>
                                    <img src={banner} alt="banner_image" className='banner_car' ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner