import React from 'react';
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import logo from '../Asset/images/aseel_logo.png'

const Contacts = () => {
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            company: "",
            message: ""
        }
    })

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address format")
            .required("Email is required"),
        name: Yup.string().required(),
        message: Yup.string().required(),
        company: Yup.string().required(),
        phone: Yup.number().required(),
        onSubmit: userInputData => {
            console.log(userInputData);
        }
    });

    return (
        <div className='contact  bg-[#272727] pt-12 pb-12 md:pt-20 md:pb-20' id='contact'>
            <div className='container'>
                <h2 className='text-center text-4xl text-white font-medium'>GET <span className='text-[#c5a57e]'>IN TOUCH</span></h2>
                <div className='row  pt-12 md:pt-28'>
                    <div className='offset-md-1 col-md-4 pb-3 md:pb-0'>
                        <nav>
                            <ul>
                                <li>
                                    <a href="#">+7(212) 654-33-35 </a>
                                </li>
                                <li>
                                    <a href="#">+7(212) 654-84-78 </a>
                                </li>
                                <li>
                                    <a href="#">info@gosearch.com</a>
                                </li>
                                <li>
                                    <a href="#">USA,New York,57 Quigly Bridge</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-md-7 '>
                        <form onSubmit={formik.handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <div className='row'>
                                                <div className='col-md-6 form_type'>
                                            <input type="text" placeholder="Name" name="name" className="form-control p-3 bg-[#323232] border-none text-black" />
                                                </div>
                                                <div className='col-md-6 md:mb-2  form_type'>
                                            <input type="text" placeholder="Phone" name="phone" className="form-control p-3 bg-[#323232] border-none text-black" />
                                                </div>
                                                <div className='col-md-6  form_type'>
                                            <input type="email" placeholder="Email*" name="email" className="p-3 bg-[#323232] border-none text-black form-control" />
                                                </div>
                                                <div className='col-md-6  form_type'>
                                            <input type="text" placeholder="Company" name="company" className="form-control p-3 bg-[#323232] border-none text-black" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <textarea type="text" placeholder='Message' name="message" className="form-control p-3 bg-[#323232] border-none text-black" rows={4} />
                                                </div>
                                                <div className='col-md-6 submit_btn'>
                                                    <button type="submit" className='font-medium'>SEND</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                    </div>
                    <div className=' offset-md-1 col-md-8 mt-4'>
                        <div className='container'>
                            {/* <h1 className='text-5xl text-white '>ASEEL</h1> */}
                            <img src={logo} alt='logo' width='150px'></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts