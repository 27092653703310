import React from 'react'
import engine from '../Asset/images/Engine.png'
import internal from '../Asset/images/Internal.png'
import battery from '../Asset/images/Battery.png'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';


const Tech = () => {

useEffect(()=>{
    AOS.init();
    const btns = document.getElementsByClassName("tech_shadow");
    for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("mouseover", function () {
            var current = document.getElementsByClassName("active");
            current[0].className = current[0].className.replace(" active", "");
            this.className += " active";
        });
    }

    $(document).ready(function () {
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            var windowCenter = $(window).height() / 2;
            var elements = $("#one, #two, #three");
            elements.each(function () {
                var element = $(this);
                var elementCenter = element.offset().top + (element.height() / 2);
                if (Math.abs(elementCenter - scroll - windowCenter) < 200) {
                    element.addClass("active");
                } else {
                    element.removeClass("active");
                }
            });
        });
    });

},[])

    return (
        <div className='technology  '>
            <div className='container p-0'>
                <div className='row gx-0'>
                    <div className='col-md-2'>
                        <div className='tech flex justify-center items-center'>
                            <h1 className='text-6xl pt-5 pb-5 md:text-8xl font-thin text-white'>Technology</h1>
                        </div>
                    </div>
                    <div className='col-md-10 tech_leter  tech_shadows'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div id="one" className='row flex tech_shadow  active  justify-between items-center pt-6 pb-6 pl-4 {style}'>
                                    <div className='col-md-4 '>
                                        <h1 className='text-5xl md:text-7xl font-thin  text-white'>Engine</h1>
                                    </div>
                                    <div className='col-md-4  mb-3 mt-3 md:mt-0 md:mb-0'>
                                        <img src={engine} alt="engine" className='w-72' ></img>
                                    </div>
                                    <div className='col-md-4  mb-3 md:mb-0'>
                                        <p className='text-1xl text-white'>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                    </div>
                                </div>
                                <div id="two" className='row flex tech_shadow  justify-start items-center pt-5 pb-5 pl-4'>
                                    <div className='col-md-4  mb-5 md:mb-0 '>
                                        <h1 className='text-5xl md:text-7xl font-thin text-white'>Battery</h1>
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={battery} alt="engine" className='w-80' ></img>
                                    </div>
                                </div>
                                <div id="three" className='row flex tech_shadow justify-start items-center pt-2 pb-2 pl-4'>
                                    <div className='col-md-4 mb-5 md:mb-0'>
                                        <h1 className='text-5xl md:text-7xl font-thin text-white'>Internal</h1>
                                    </div>
                                    <div className='col-md-6 mb-5 md:md-0 '>
                                        <img src={internal} alt="engine" className='w-90' ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tech