import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import 'swiper/css';
import './index.css'

import {
  BrowserRouter
} from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
  <App />
  </BrowserRouter>
  ,
  document.getElementById('root')
);
