import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import logos from '../Asset/images/aseel_logo.png'

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

	return (
			<header className="navbar">
			{/* <h2 className='text-4xl'>ASEEL</h2> */}
			<img src={logos} alt='logo' width='100px'></img>
			<nav ref={navRef}>
				<a href="/#">Home</a>
				<a href="#contact">Contact Us</a>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button className="nav-btn" onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;
