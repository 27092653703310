import React from 'react';
import car1 from "../Asset/images/car01.png"
import car2 from "../Asset/images/car02.png"
import car3 from "../Asset/images/car03.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

const Exterier = () => {
    return (
        <>
            <div className='exterier bg-[#272727]'>
                <div className='container'>
                    <div className='flex items-end justify-start md:justify-end  pt-7 pb-2 md:pt-20'>
                        <h1 className='text-7xl  text-right md:text-8xl font-thin text-white opacity-45  pr-5'>Exterier</h1>
                    </div>
                </div>
            </div>
            <div className=' bg-[#3d3d3d] exterier_mains'>
                <div className='container p-0'>
                    <div className='row exterier_images gx-0 desktop_device '>
                        <div className='col-md-7 exterirt_letter'>
                            <div className='images_exterier'>
                                <img src={car1} alt="car" ></img>
                                <img src={car2} alt="car"></img>
                                <img src={car3} alt="car" ></img>
                            </div>
                        </div>
                        <div className='col-md-5 car_frame'>
                            {/* <img src={exterier} alt="car_frame"></img> */}
                        </div>
                    </div>
                    <div className='mobile_device'>
                        <div className='row'>
                        <div className='col-md-12 car_view'>
                                <img src={car1} alt="car" className='cars' ></img>
                                <img src={car2} alt="car" className='cars' ></img>
                                <img src={car3} alt="car" className='cars' ></img>
                        </div>
                            {/* <Swiper
                                spaceBetween={0}
                                slidesPerView={3}
                                loop={true}
                                speed= {500}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                            >
                                <SwiperSlide>
                                    <img src={car1} alt="car" ></img>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={car2} alt="car"></img>
                                </SwiperSlide>
                                <SwiperSlide><img src={car3} alt="car" ></img></SwiperSlide>
                            </Swiper> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Exterier